import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_solutions.js";
import { Link } from "gatsby";
import { Image, Video, Gallery, Thumb } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p className="leading-relaxed text-lg">
  Get high quality print data with our detailed and customizable topographical
  maps for a uniform and seamless presentation of your activities online &
  offline. For a realistic look we can create a 3D model of your destination and
  activities.
    </p>
    <br />
    <br />
    <h4>{`Customizable maps`}</h4>
    <p>{`We can provide high quality print data for your paper maps, brochures and info boards.
Quickly realize your project with our set of topographical maps, satellite
images or customized designs. The map design can also be customized.`}</p>
    <Image src="solutions/printproducts6.png" className="my-12 w-4/5 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Uniform look`}</h4>
    <p>{`Use the Trailguide maps and content to get a uniform look throughout your
communication channels. In the Trailguide app, on your website and on your print
products, the content and maps have a unified appearance, making it easier for
your guests to browse the content and find what they need.`}</p>
    <br />
    <p>{`One single source of data ensures that all information on all surfaces is
in harmony. It also saves costs since you can reuse the same source of data
for different purposes.`}</p>
    <Image src="solutions/presentation.png" className="my-12 w-3/4 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`3D maps`}</h4>
    <Video className="float-right w-96 ml-12" src="0pwuzaNVuoI" style={{
      height: "17rem",
      paddingBottom: "0px"
    }} mdxType="Video" />
    <p>{`The best way to get a good understanding of the terrain and the routes of your
activities is a 3D view. We select and edit high quality elevation data from
national offices for cartography. This high resolution data that is
gatherd from airborne LIDAR scanners.`}</p>
    <br />
    <p>{`The texture can either be a realistic satellite picture, the Trailguide topo
map, or any other customized design. On top of the terrain texture we can show
the tracks of the activities, points of interest, and text lables.`}</p>
    <br />
    <p>{`The legend can be designed individually. Only the names of the routes, or more
information with info boxes like the length, a height profile, or a description.`}</p>
    <br />
    <p>{`With one of the Trailguide professional licenses, the printed maps can be connected
to the digital content in the app through a QR-code.
All necessary information instantly shows up on your guests phone.`}</p>
    <p>{`Digital information is easy to keep up to date. The app provides a navigation
function to find the activities, and allows your guests to give feedback.`}</p>
    <Image src="solutions/3d-map-hemsedal-lowres.png" className="my-12 w-3/4 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Vintage maps`}</h4>
    <p>{`The vintage map is a real eye-catcher with many details and interesting textures.
Modern data is presented in a simple retro style. The terrain, forest and water
are kept in subtle and natural colors. Buildings and infrastructure settle smoothly
into the picture.`}</p>
    <Image src="solutions/map-skigaarden-2.jpeg" className="my-12 w-3/4 mx-auto" mdxType="Image" />
    <br />
    <p>{`Lines and objects are drawn as if by hand with pencil or ink. Activities that are
in focus are distinguished by slightly more intense colors. Important places,
mountains, water bodies and activities are marked in the style of historical fonts.`}</p>
    <br />
    <p>{`A combination of eight special techniques accentuates the terrain to create a
three-dimensional impression on the flat print. It helps the viewer to understand
the structure of the earth's surface. Mountains and valleys can thus be easily
distinguished. With the help of the latest laser data, even the smallest textures
of the landscape are drawn and offer the eye many details to explore.`}</p>
    <Image src="solutions/map-skigaarden-skigaarden.jpeg" className="my-12 w-3/4 mx-auto" mdxType="Image" />
    <br />
    <p>{`Vector data and high-resolution textures enable large print formats. The maps
can be designed individually. Your business or place of residence can be
highlighted and placed in the center of the map. If desired, different activities
can be overlayed and highlighted.`}</p>
    <p>{`The map will become an attraction in your company, or home!`}</p>
    <Image src="solutions/map-skigaarden-terrain.jpg" className="my-12 w-3/4 mx-auto" mdxType="Image" />
    <br />
    <p>{`See all crops at 100% to get an impression of the style and details of the map:`}</p>
    <div className="mx-auto w-4/5">
  <Gallery mdxType="Gallery">
    <Thumb src="solutions/map-skigaarden-skigaarden.jpg" mdxType="Thumb">
      Center of the map. Skigaarden restaurant and apartments highlighted.
    </Thumb>
    <Thumb src="solutions/map-skigaarden-hemsedal.jpg" mdxType="Thumb">Hemsedal village.</Thumb>
    <Thumb src="solutions/map-skigaarden-rivers-2.jpg" mdxType="Thumb">Rivers</Thumb>
    <Thumb src="solutions/map-skigaarden-rivers-1.jpg" mdxType="Thumb">Valley</Thumb>
    <Thumb src="solutions/map-skigaarden-terrain.jpg" mdxType="Thumb">Terrain</Thumb>
    <Thumb src="solutions/map-skigaarden-gorge.jpg" mdxType="Thumb">Gorge</Thumb>
    <Thumb src="solutions/map-skigaarden-peaks.jpg" mdxType="Thumb">Peaks</Thumb>
    <Thumb src="solutions/map-skigaarden-maplable.jpg" mdxType="Thumb">Lable</Thumb>
    <Thumb src="solutions/map-skigaarden-legend.jpg" mdxType="Thumb">Legend</Thumb>
    <Thumb src="solutions/map-skigaarden-qrcode.jpg" mdxType="Thumb">QR code</Thumb>
  </Gallery>
    </div>
    <br />
    <p>{`The print can be complemented by Trailguide's digital platform. A QR code brings
the viewer to the same map section in the web app with all activities, additional
information and functions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      